//import './assets/font/simple-line-icons/css/simple-line-icons.css';
//import './assets/font/iconsmind-s/css/iconsminds.css';
//import 'bootstrap/dist/css/bootstrap.min.css';
//import 'react-perfect-scrollbar/dist/css/styles.css';

let render = () => {
    import('./asset/sass/build/app.scss').then((x) => {
        require('./AppRenderer');
    });
};
render();
