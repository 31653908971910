import { combineReducers } from 'redux';

import AuthReducer from './AuthReducer';
import ErrorReducer from './ErrorReducer';
import LayoutReducer from './LayoutReducer';
import RouteReducer from './RouteReducer';
import TrackReducer from './TrackReducer';
import UserReducer from './UserReducer';

export default combineReducers({
    auth: AuthReducer,
    error: ErrorReducer,
    layout: LayoutReducer,
    route: RouteReducer,
    track: TrackReducer,
    user: UserReducer,
});
