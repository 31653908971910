//AUTH
export const CLEAR_AUTH = 'CLEAR_AUTH';
export const MERGE_AUTH = 'MERGE_AUTH';
export const SET_AUTH = 'SET_AUTH';

//ERROR
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const SET_ERROR = 'SET_ERROR';

//LAYOUT
export const CLEAR_LAYOUT = 'CLEAR_LAYOUT';
export const MERGE_LAYOUT = 'MERGE_LAYOUT';
export const SET_LAYOUT = 'SET_LAYOUT';

//ROUTE
export const CLEAR_ROUTE = 'CLEAR_ROUTE';
export const MERGE_ROUTE = 'MERGE_ROUTE';
export const SET_ROUTE = 'SET_ROUTE';

//TRACK
export const CLEAR_TRACK = 'CLEAR_TRACK';
export const MERGE_TRACK = 'MERGE_TRACK';
export const SET_TRACK = 'SET_TRACK';

//USER
export const CLEAR_USER = 'CLEAR_USER';
export const MERGE_USER = 'MERGE_USER';
export const SET_USER = 'SET_USER';
