import { CLEAR_AUTH, MERGE_AUTH, SET_AUTH } from '../types';
import * as R from 'ramda';

const initialState = {
    token: '',
    isValid: false,
};

export default function (state = initialState, action) {
    switch (action.type) {
        case CLEAR_AUTH:
            return R.clone(initialState);
        case MERGE_AUTH:
            return R.mergeDeepRight(state, action.payload);
        case SET_AUTH:
            return { ...state, ...action.payload };
        default:
            return state;
    }
}
