import { CLEAR_TRACK, MERGE_TRACK, SET_TRACK } from '../types';
import * as R from 'ramda';

const initialState = {
    countList: [],
    countChart: {
        data: [],
        keys: [],
    },
};

export default function (state = initialState, action) {
    switch (action.type) {
        case CLEAR_TRACK:
            return R.clone(initialState);
        case MERGE_TRACK:
            return R.mergeDeepRight(state, action.payload);
        case SET_TRACK:
            return { ...state, ...action.payload };
        default:
            return state;
    }
}
