import { CLEAR_ROUTE, MERGE_ROUTE, SET_ROUTE } from '../types';
import * as R from 'ramda';

const initialState = {
    back: '',
    current: '',
    redirect: '',
    title: '',
};

export default function (state = initialState, action) {
    switch (action.type) {
        case CLEAR_ROUTE:
            return R.clone(initialState);
        case MERGE_ROUTE:
            return R.mergeDeepRight(state, action.payload);
        case SET_ROUTE:
            return { ...state, ...action.payload };
        default:
            return state;
    }
}
